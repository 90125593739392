@import url('https://fonts.googleapis.com/css2?family=Alexandria:wght@100..900&display=swap');


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Alexandria", sans-serif;
  direction: ltr;
}
body {
  background-color: #f1f1f1;
}


