:root {
    --main-color-- : #082c4c;
    --second-color-- : #ffffff;
    --shadow-color-- : #061019;
    --active-color-- : #6A8093;
}
.content-section {
    background-image: url(../images/Riyadh.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
   height: 40vh;
   /* background-color: var(--main-color--); */
}
.content-section::before {
    background: linear-gradient(to top, var(--shadow-color--) 0%, var(--main-color--) 100%);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    z-index: -1;
}
.heading-box {
    /* height: 40vh; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.heading {
    color: var(--second-color--);
    position: relative;
}
.heading::before {
    content: "";
    position: absolute;
    top: -2rem;
    right: 0rem;
    width: 50%;
    height: 1rem;
    background-color: var(--active-color--);
}
.heading::after {
    content: "";
    position: absolute;
    bottom: -2rem;
    left: 0rem;
    width: 50%;
    height: 1rem;
    background-color: var(--second-color--);
}
.contact-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.box-content {
    width: 100%;
    /* background-color: #6A8093; */
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.box-content .box-number {
    /* font-weight: bold; */
    color: var(--second-color--);
    text-decoration: none;
}

.box-icon {
    /* 
    ;
    border: 2px solid var(--main-color--); */
    width: 3rem;
    height: 3rem;
    border-radius: 20%;
    background-color: var(--second-color--);
    color: var(--main-color---);
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-icon img {
    width: 1.5rem;
}
.social-media-icons {
    width: 100%;
    /* background-color: aquamarine; */
    color: var(--second-color--);
    display: flex;
    flex-direction: row;
    /* gap: 1rem; */
    justify-content: center;
    align-items: center;
    padding: 1rem;
}
.heading-social-box {
    display: flex;
   
    gap: 4rem;
}


.social-icon {
    width: 2rem;
    transition: 0.4s ease;
    cursor: pointer;
}
.social-icon:hover {
    transform: scale(1.1,1.1);
}
.box {
    width: 100%;
    display: grid;
    gap: 1rem;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
    .box-content {
    
        /* background-color: #6A8093; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
    }
    .content-section {
        height: 60vh;
    }
}