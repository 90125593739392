
:root {
    --main-color-- : #082c4c;
    --second-color-- : #ffffff;
    --shadow-color-- : #061019;
    --active-color-- : #6A8093;
}
.footer-section {
    padding: 1rem;
    background-color: var(--main-color--);
    color: var(--second-color--);
    display: flex;
    justify-content: center;
    align-items: center;
}
.brc {
    color: var(--active-color--);
    font-weight: bold;
}