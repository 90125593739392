:root {
    --main-color-- : #082c4c;
    --second-color-- : #ffffff;
    --shadow-color-- : #061019;
    --active-color-- : #6A8093;
}
.bd-bottom {
    border-bottom: 1px solid #eaeaea;
}
.feature {
    height: 12rem;
    border-radius: 0.5rem;
    background-color: var(--second-color--);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-left: 2rem;
    gap: 1rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.4s ease;
    border-left: 0.1rem solid var(--main-color--);
    /* border-left: 0.1rem solid #eee; */
    direction: ltr;
}
.feature:hover {
    transform: scale(1.1,1.1);
}
.feature:hover .feature-icon{
    width: 16rem;
}
.feature .feature-icon {
    /* 
    ;
    border: 2px solid var(--main-color--); */
    width: 10rem;
    height: 10rem;
    border-radius: 10%;
    background-color: var(--main-color--);
    color: var(--main-color---);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s ease;
}
.feature-icon img {
    width: 2.5rem;
}
.feature .feature-title {
    font-weight: bold;
    color: var(--active-color--);
    transition: 0.8s ease;
}
.feature:hover .feature-title{
    text-decoration: underline;
}
.section-heading {
    position: relative;
}
.section-heading p {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}
.section-heading::before {
    content: "";
    position: absolute;
    bottom: -1rem;
    right: 0;
    width: 40rem;
    margin: auto;
    height: 0.18rem;
    background-image: linear-gradient(to top, var(--shadow-color--) 0%, var(--main-color--) 100%);
}
.section-heading::after {
    content: "";
    position: absolute;
    top: -1rem;
    left: 0;
    width: 40rem;
    margin: auto;
    height: 0.18rem;
    background-image: linear-gradient(to top, var(--shadow-color--) 0%, var(--main-color--) 100%);
}
.feature-information {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    padding: 1rem;
    gap: 0.5rem;
}
.feature-description {
    color: var(--main-color--);
    font-size: 14px;
}
.feature-title {
    font-weight: bold;
    font-size: 2rem;
}

@media only screen and (min-width: 320px) and (max-width: 480px) {
    /* .feature-description {
        display: none;
    } */
    .feature-title {
        font-weight: bold;
        font-size: 1rem;
    }
    .feature {
        height: 32rem;
        border-radius: 0.5rem;
        background-color: var(--second-color--);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        gap: 1rem;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        transition: 0.4s ease;
        border-left: 0.1rem solid var(--main-color--);
        /* border-left: 0.1rem solid #eee; */
    }
    .feature-information {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;
        padding-left: 2rem;
        gap: 0.5rem;
    }
    .feature:hover {
        transform: scale(0.9,0.9);
    }

    .feature .feature-icon {
        /* 
        ;
        border: 2px solid var(--main-color--); */
        width: 16rem;
        height: 12rem;
        border-radius: 10%;
        background-color: var(--main-color--);
        color: var(--main-color---);
        display: flex;
        justify-content: center;
        align-items: center;
    }
}