:root {
    --main-color-- : #082c4c;
    --second-color-- : #ffffff;
    --shadow-color-- : #061019;
    --active-color-- : #6A8093;
}
.header-section {
    background-color:  var(--main-color--);
    width: 100%;
    height: 6rem;
    z-index: 999;
    padding: 10px 0;

}
.header-color {
    background-color: var(--main-color--);
}
.navbar {
    background-color:  var(--main-color--);
    padding: 1.5rem;
    /* border-bottom: 0.08rem solid var(--main-color--); */

    
}
.navbar a {
    color: var(--second-color--);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .55);
}

.navbar-light .navbar-nav .nav-link {
    color: var(--second-color--);
}
.navbar-light .navbar-nav .nav-link.active {
    position: relative;
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: var(--active-color--);
    font-weight: bold;
}

.navbar-light .navbar-nav .nav-link.active::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 0.10rem;
    
    background-color: var(--active-color--);
    bottom: 0;
    right: 0;
}
.brc-logo {
    width: 2.5rem;
}

.navbar-light .navbar-toggler-icon {
    background-image: url('../images/menu.png');
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: var(--second-color--);
    text-align: right;
    list-style: none;
    background-color: var(--active-color--);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}