:root {
    --main-color-- : #082c4c;
    --second-color-- : #ffffff;
    --shadow-color-- : #061019;
    --active-color-- : #6A8093;
}
.row {
    --bs-gutter-x: 0rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x)* -.5);
    margin-left: calc(var(--bs-gutter-x)* -.5);
}
.hero-section {
    background-image: url(../images/Riyadh.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: scroll;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}
.hero-section:before {
    background: linear-gradient(to top, var(--shadow-color--) 0%, var(--main-color--) 100%);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0.8;
    z-index: -1;
}
.hero-inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
h1 {
    /* font-size: 52px; */
    font-weight: 700;
    line-height: 1.5;
    font-size: 3.5rem;
    /* margin: 0 0 25px; */
    color: var(--second-color--);
    letter-spacing: -1px;
    text-align: center;
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
    h1 {
        /* font-size: 52px; */
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.5rem;
        /* margin: 0 0 25px; */
        color: var(--second-color--);
        letter-spacing: -1px;
        text-align: center;
    }
}
.hero-content {
    display: flex;
    flex-direction: column;
}
.hero-content p {
    color: var(--second-color--);
    line-height: 1.5;
    margin: 0 0 25px;
}
.download-btn {
    padding: 0.8rem 5rem;
    border: 0.08rem solid var(--second-color--);
    text-decoration: none;
    border-radius: 4rem;
    transition: 0.8s ease;
    color: var(--second-color--);
}
.download-btn:hover {
    background-color: var(--main-color--);
    color: var(--second-color--);
}
.bg-spaning {
    background-color: var(--active-color--);
}